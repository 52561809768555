import { useLocalStorage } from '@vueuse/core'

export default defineNuxtPlugin(() => {
  const cookiesAccepted = useLocalStorage('cookies-accepted-v1', false)

  if (cookiesAccepted.value) return

  setTimeout(() => {
    useToast().add({
      closeButton: null,
      timeout: 0,
      title: 'Diese Website verwendet Cookies',
      description: 'Mehr Informationen finden Sie in unserer <a href="/datenschutz"><u>Datenschutzerklärung</u></a>.',
      actions: [
        {
          label: 'Verstanden',
          color: 'green',
          click: () => cookiesAccepted.value = true
        }
      ]
    })
  }, 3000)
})
