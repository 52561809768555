<script lang="ts" setup>
const reviews = [
  {
    name: 'Max H.',
    time: 'vor 12 Minuten',
    text: 'Super einfacher Prozess! Hatte meine Abmeldung in wenigen Minuten erledigt. Sehr empfehlenswert!',
    rating: 5
  },
  {
    name: 'Laura M.',
    time: 'vor 2 Stunden',
    text: 'Einfach und schnell, aber etwas verwirrend beim Hochladen der Dokumente. Trotzdem insgesamt sehr zufrieden.',
    rating: 4
  },
  {
    name: 'Sarah K.',
    time: 'vor 3 Stunden',
    text: 'Toller Service! Endlich muss ich nicht mehr zur Behörde fahren. Hat alles reibungslos geklappt.',
    rating: 5
  },
  {
    name: 'Peter B.',
    time: 'gestern',
    text: 'Sehr praktisch und benutzerfreundlich. Musste den Sicherheitscode mehrmals korrigieren, aber ansonsten top.',
    rating: 4
  },
  {
    name: 'Julia F.',
    time: 'gestern',
    text: 'Einfach genial! Konnte mein Auto in wenigen Klicks abmelden. Werde den Service auf jeden Fall weiterempfehlen.',
    rating: 5
  },
  {
    name: 'Andreas L.',
    time: 'gestern',
    text: 'Guter Service, aber es hat etwas länger gedauert als erwartet. Trotzdem viel besser als der Behördengang.',
    rating: 4
  },
]

const trackEvent = (category: string, action: string) => {
  console.log(`Tracking event: ${category} - ${action}`)
}
</script>

<template lang="pug">
UCarousel(
  :items="reviews"
  :ui="{ item: 'basis-full shrink-0', arrows: { wrapper: 'flex gap-4 items-center' } }"
  arrows
)
  template(#default='{ item }')
    .item.w-full.py-6
      .stars.text-yellow-500
        template(v-for="i in item.rating")
          span ★
        template(v-for="i in 5 - item.rating")
          span ☆

      p.text-2xl.mt-3 {{ item.text }}

      p.font-semibold.mt-4.text-lg - {{ item.name }}
      p.font-light {{ item.time }}

  
  template(#prev='{ onClick, disabled }')
    UButton(
      :disabled='disabled' 
      @click='onClick' 
      icon="i-heroicons-chevron-left"
      size="sm"
      :ui="{ padding: { sm: 'px-2.5 py-2.5' } }"
      v-ga-event="{ name: 'review_slider', action: 'arrow_navigation' }"
    )
  template(#next='{ onClick, disabled }')
    UButton(
      :disabled='disabled' 
      @click='onClick' 
      icon="i-heroicons-chevron-right"
      size="sm"
      :ui="{ padding: { sm: 'px-2.5 py-2.5' } }"
      v-ga-event="{ name: 'review_slider', action: 'arrow_navigation' }"
    )
</template>

<style lang="sass">

</style>
