<script lang="ts" setup>

</script>

<template lang="pug">
.grid.grid-cols-1.gap-12(class="md:grid-cols-5")
  .col-span-1(class="md:col-span-3")
    SectionIntro
      template(#section-title) Offizielles Mitglied

    p.text-gray-600.text-xl.mt-4(class="lg:text-2xl") Digitalisierungsprojekt des Kraftfahrt-Bundesamts
    p.mt-6.font-light.
      Als offizielles Mitglied stehen wir in engem Austausch mit dem Kraftfahrt-Bundesamt (KBA)
      und dem fachaufsichtsführenden Bundesministerium für Digitales und Verkehr (BMDV) und
      unterstützen die Behörden dabei, die digitalen Zulassungsprozesse in Deutschland voranzubringen.

  .col-span-1.flex.flex-col.justify-center(class="md:col-span-2")
    img(
      src="/images/logo-offizielles-mitglied-kba.svg"
      alt="Icon: Deutschland-Adler"
      loading="lazy"
    ).h-14.w-auto.mr-auto

    nuxt-link(to="/ausserbetriebsetzung/vorbereitung")
      UButton.mt-6(
        size="sm"
        v-ga-event="{ name: 'cta_button', action: 'section_offizielles_mitglied' }"
      ) Jetzt online abmelden
</template>

<style lang="sass">

</style>
