import type { Directive } from 'vue'

type BindingValue = {
  name: string
}

const vGaEvent: Directive<HTMLElement, BindingValue> = {
  beforeMount (el, binding) {
    const { name, ...rest} = binding.value

    el.onClickListener = () => {
      useNuxtApp().$gtag('event', name, { ...rest })
    }

    el.addEventListener('click', el.onClickListener)
  },
  beforeUnmount (el) {
    el.removeEventListener('click', el.onClickListener)
  }
}

export default defineNuxtPlugin((ctx) => {
  ctx.vueApp.directive('ga-event', vGaEvent)
})
