<script lang="ts" setup>
import { useWindowScroll, watchDebounced } from '@vueuse/core'

const { y } = useWindowScroll()

const showStickyButton = ref(false)

watchDebounced(y, () => {
  if (y.value > 2000) {
    showStickyButton.value = true
  } else {
    showStickyButton.value = false
  }
}, { debounce: 100, maxWait: 400 })
</script>

<template lang="pug">
.sticky-cta-button.fixed.bottom-6.flex.justify-center.w-full(:class="{ show: showStickyButton }")
  nuxt-link(to="/ausserbetriebsetzung/daten/generell")
    UButton(
      v-ga-event="{ name: 'cta_button', action: 'sticky' }"
    ) Jetzt Fahrzeug abmelden
</template>

<style lang="sass" scoped>
.sticky-cta-button
  transition: transform 0.3s
  transform: translateY(150px)
  z-index: 1000

  &.show
    transform: translateY(0)

  button
    @apply border-4 border-white drop-shadow-xl
</style>
