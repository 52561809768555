<script lang="ts" setup>
export interface Props {
  locality: string
}

const props = withDefaults(defineProps<Props>(), {
  locality: ''
})

const columns = [{
  key: 'label',
  label: ''
}, {
  key: 'office',
  label: 'Bei der Zulassungsstelle' + (props.locality ? `  ${props.locality}` : '')
}, {
  key: 'digital',
  label: 'Online bei Abmeldung.digital'
}]

const comparisons = [{
  label: 'Offizielle Bescheinigung',
  office: '✔️ in Papierform',
  digital: '✔️ als PDF'
}, {
  label: 'Zeitaufwand',
  office: 'ca. 90 Minuten',
  digital: '✔️ ca. 3 Minuten'
}, {
  label: 'Wartezeiten',
  office: '30 Min. - 120 Min.',
  digital: '✔️ keine Wartezeiten'
}, {
  label: 'Termin',
  office: 'muss vereinbart werden',
  digital: '✔️ kein Termin notwendig'
}, {
  label: 'Öffnungszeiten',
  office: 'Öffnungszeiten der Behörde',
  digital: '✔️ immer geöffnet'
}]
</script>

<template lang="pug">
UTable(:columns="columns" :rows="comparisons").online-vs-offline-table
</template>

<style lang="sass" scoped>
:deep
  table
    tbody tr:nth-child(odd)
      @apply bg-gray-50
</style>
