<script lang="ts" setup>
const items = [
  {
    label: 'Ist der Online-Service sicher und vertrauenswürdig?',
    shortLabel: 'ist_service_sicher',
    content: 'Unsere Software wurde vom Kraftfahrtbundesamt überprüft und genehmigt, was bestätigt, dass der Service sicher und vertrauenswürdig ist. Bei weiteren Fragen stehen wir Ihnen gerne zur Verfügung.'
  },
  {
    label: 'Fallen Gebühren für diesen Service an?',
    shortLabel: 'fallen_gebuehren_an',
    slot: 'gebuehren'
  },
  {
    label: 'Welche Zahlungsmöglichkeiten gibt es?',
    shortLabel: 'zahlungsmoeglichkeiten',
    content: 'Sie haben bei uns die Wahl zwischen den am weitesten verbreiteten und vertrauenswürdigsten Zahlungsmethoden: Paypal, Kreditkarte, Klarna auf Rechnung und SEPA-Lastschriftmandat.'
  },
  {
    label: 'Gibt es eine Schritt-für-Schritt-Anleitung oder ein Tutorial?',
    shortLabel: 'schritt_fuer_schritt',
    content: 'Natürlich!  Wir haben eine leicht verständliche Schritt-für-Schritt-Anleitung entwickelt, die Sie sicher durch jeden Abschnitt des Prozesses führt. Unser Tutorial verbindet klare Anweisungen mit visuellen Hilfsmitteln, sodass Sie genau wissen, was als Nächstes zu tun ist.'
  },
  {
    label: 'Erhalte ich eine Bestätigung, dass mein Fahrzeug erfolgreich außer Betrieb gesetzt wurde?',
    shortLabel: 'erhalte_ich_eine_bestaetigung',
    slot: 'bestaetigung'
  },
  {
    label: 'Wie lange dauert der gesamte Prozess der Außerbetriebsetzung online?',
    shortLabel: 'wie_lange_dauert_prozess',
    slot: 'dauer'
  },
  {
    label: 'Welche Dokumente werden benötigt, um das Fahrzeug außer Betrieb zu setzen?',
    shortLabel: 'welche_dokumente',
    slot: 'dokumente'
  }
]

const { ausserbetriebsetzung } = await usePrice()

const trackOpen = (index: number) => {

  trackEvent('open_faq_question', `${items[index].shortLabel}`)
}

</script>

<template lang="pug">
.text-left.pt-6.faq-section.max-w-3xl.mx-auto
  UAccordion(:items="items", color="gray", size="xl", @open="trackOpen")
    template(#gebuehren)
      p.
        Ja, für unseren Online-Service zur Außerbetriebsetzung Ihres Fahrzeugs fallen Gebühren an.
        Die Gesamtkosten belaufen sich auf {{ ausserbetriebsetzung.asString }} – und das inklusive aller Gebühren bei den Zulassungsstellen.

    template(#bestaetigung)
      p.
        Natürlich erhalten Sie eine Bestätigung, sobald Ihr Fahrzeug erfolgreich außer Betrieb gesetzt wurde.
        Und wir machen es Ihnen so einfach wie möglich:
      
      ol
        li.
          <strong>Sofortiger Download</strong>: Unmittelbar nach der erfolgreichen Außerbetriebsetzung steht
          Ihre Abmeldebescheinigung zum sofortigen Download bereit. So haben Sie gleich die Bestätigung in der Hand.

        li.
          <strong>Email-Versand</strong>: Zusätzlich senden wir Ihnen die Abmeldebescheinigung umgehend per E-Mail zu.
          Damit haben Sie eine digitale Kopie, die Sie jederzeit einsehen können.

    template(#dauer)
      p.
        Wenn Sie schon alles vorbereitet haben, können Sie den Prozess in etwa 3 Minuten abschließen. Mit Vorbereitung dauert es maximal 10 Minuten.

      p.mt-2.
        Hier ein Überblick über die Zeitinvestition, die Sie erwarten können:

      ol
        li.
          <strong>Abmontieren der Kennzeichen vom Fahrzeug</strong>: Dieser Schritt sollte ebenfalls nicht mehr als 5 Minuten
          Ihrer Zeit in Anspruch nehmen.

        li.
          <strong>Freilegung der Sicherheitscodes</strong>: Das Aufdecken der verdeckten Sicherheitscodes sowohl auf dem
          Kennzeichen als auch auf der Zulassungsbescheinigung Teil 1 wird voraussichtlich nur ca. 2 Minuten dauern.

        li.
          <strong>Eingabe der Daten</strong>: Ihre Dateneingabe können Sie in nur einer Minute erledigen.

        li.
          <strong>Automatisierte Rückmeldung</strong>: Die Bestätigung der erfolgreichen Außerbetriebsetzung durch die Zulassungsstelle
          erfolgt automatisiert. Es kann einige Minuten dauern, bis Sie Ihre Bestätigung herunterladen können. Wir senden Ihnen in jedem Fall
          eine E-Mail mit der Bestätigung, sobald diese verfügbar ist.


    template(#dokumente)
      p Die Dokumente, die Sie benötigen, sind:

      ol
        li Kennzeichen Vorne und Hinten
        li Fahrzeugschein bzw. Zulassungsbescheinigung Teil 1

      p.mt-2
        strong Wichtige Voraussetzung:
      
      p.mt-1.
        Es ist entscheidend, dass die Zulassung Ihres Fahrzeugs nach dem 01.01.2015 erfolgt ist. Ab diesem Datum wurden die neuen
        Stempelplaketten und die Zulassungsbescheinigungen Teil 1 mit einem verdeckten Sicherheitscode eingeführt, die für die
        Außerbetriebsetzung in unserem Online-Service erforderlich sind.

</template>

<style lang="sass">
.faq-section
  span
    text-align: left

  ol
    list-style-type: decimal
    padding-left: 1rem

    li
      margin-top: 0.75rem

  .i-heroicons-chevron-down-20-solid
    flex-shrink: 0
</style>
