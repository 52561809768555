<script lang="ts" setup>
definePageMeta({
  layout: 'default',
})

useSeoMeta({
  title: 'Kfz-Abmeldung online - Außerbetriebsetzung in 3 Min.'
})
</script>

<template lang="pug">
#index-page.bg-white
  LandingPage

LayoutFooter

</template>

<style lang="sass">
#index-page
</style>
