import { createTRPCClient, httpLink } from '@trpc/client'
import type { AppRouter } from '../../../api-zulassung-digital/trpc/routers'
import superjson from 'superjson'

export type TRPCClient = ReturnType<typeof createTRPCClient<AppRouter>>

export default defineNuxtPlugin(() => {
  const trpc = createTRPCClient<AppRouter>({
    links: [
      httpLink({
        url: `${useRuntimeConfig().public.backendUrl}/trpc`,
        async fetch (url, options) {
          const res = await fetch(url, {
            ...options,
            credentials: 'include',
          })

          if (res.status === 401) {
            useRouter().push('/otp')
          }

          return res
        },
        transformer: superjson
      })
    ],
  })

  return {
    provide: {
      trpc
    }
  }
})
