import Feathers from '@feathersjs/feathers'
import Restclient from '@feathersjs/rest-client'

export default defineNuxtPlugin(() => {
  const rest = Restclient(useRuntimeConfig().public.shopApiUrl as string)
  
  const feathers =  Feathers()
    .configure(rest.fetch(fetch, {
      headers: { nuxt: import.meta.server ? 'server' : 'client' }
    }))

  return {
    provide: {
      feathers
    }
  }
})
