type PriceState = {
  asNumber: number
  asString: string
}

const formatter = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })

export async function usePrice () {
  const ausserbetriebsetzung = useState<PriceState>('ab-price')
  const umschreibung = useState<PriceState>('ug-price')
  const wiederzulassung = useState<PriceState>('wg-price')
  const halteraenderung = useState<PriceState>('ha-price')
  const fahrzeugbezogeneReservierung = useState<PriceState>('fahrzeugbezogeneReservierung-price')

  const { backendUrl, source } = useRuntimeConfig().public

  await callOnce(async () => {
    const prices = await $fetch(`${backendUrl}/prices`, { query: { source }}) as {
      ausserbetriebsetzung: number,
      umschreibung: number,
      wiederzulassung: number,
      fahrzeugbezogeneReservierung: number,
      halteraenderung: number
    }
    
    ausserbetriebsetzung.value = {
      asNumber: prices.ausserbetriebsetzung,
      asString: formatter.format(prices.ausserbetriebsetzung)
    }

    umschreibung.value = {
      asNumber: prices.umschreibung,
      asString: formatter.format(prices.umschreibung)
    }
    
    wiederzulassung.value = {
      asNumber: prices.wiederzulassung,
      asString: formatter.format(prices.wiederzulassung)
    }

    fahrzeugbezogeneReservierung.value = {
      asNumber: prices.fahrzeugbezogeneReservierung,
      asString: formatter.format(prices.fahrzeugbezogeneReservierung)
    }

    halteraenderung.value = {
      asNumber: prices.halteraenderung,
      asString: formatter.format(prices.halteraenderung)
    }
  })

  return {
    ausserbetriebsetzung,
    umschreibung,
    wiederzulassung,
    fahrzeugbezogeneReservierung,
    halteraenderung
  }
}
