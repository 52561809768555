export default defineAppConfig({
  ui: {
    strategy: 'override',
    button: {      
      base: 'drop-shadow-md transition-all ease-in duration-200  disabled:cursor-not-allowed disabled:opacity-60',
      font: '',
      rounded: 'rounded-full',
      size: {
        '2xs': 'text-xs',
        xs: 'text-xs',
        sm: 'text-sm',
        md: 'text-xl',
        lg: 'text-sm',
        xl: 'text-base',
      },
      padding: {
        '2xs': 'px-2 py-1',
        xs: 'px-2.5 py-1.5',
        sm: 'px-6 py-2',
        md: 'px-10 py-3',
        lg: 'px-3.5 py-2.5',
        xl: 'px-3.5 py-2.5',
      },
      color: {
        white: {
          solid: 'bg-white text-slate-700'
        },
        dark: {
          solid: 'bg-slate-700 text-white'
        }
      },
      default: {
        size: 'md',
        variant: 'solid',
        color: 'dark',
        loadingIcon: 'i-heroicons-arrow-path-20-solid',
      },
    },
    modal: {
      container: 'flex min-h-full items-center justify-center text-center'
    },
    formGroup: {
      wrapper: 'mt-6',
      error: 'mt-2 text-red-500 dark:text-red-400 text-sm',
      label: {
        base: 'block font-bold text-lg text-gray-700 dark:text-gray-200'
      }
    },
    checkbox: {
      wrapper: 'relative flex items-start py-1',
      container: 'flex items-center h-6',
      label: 'text-base font-medium text-gray-700 dark:text-gray-200',
    },
    radio: {
      wrapper: 'relative flex items-start py-1',
      container: 'flex items-center h-6',
      label: 'text-base font-medium text-gray-700 dark:text-gray-200',
    },
    input: {
      rounded: 'rounded-full',
      variant: {
        outline: 'shadow-sm bg-white text-gray-900 dark:text-white ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400 focus:ring-2 focus:ring-{color}-500 dark:focus:ring-{color}-400',
        none: 'bg-white focus:ring-0 focus:shadow-none',
      },
      default: {
        size: 'xl'
      }
    },
    accordion: {
      'wrapper': 'w-full flex flex-col',
      'item': {
        'base': '',
        'size': 'text-base',
        'color': 'text-slate-800 dark:text-gray-400',
        'padding': 'pt-1.5 pb-6 px-3',
        'icon': 'ms-auto transform transition-transform duration-200'
      },
      'default': {
        'class': 'bg-slate-300 hover:bg-slate-400 text-slate-700 transition transition-color duration-200 mt-4 py-4 px-4 lg:text-xl font-semibold w-full rounded-2xl drop-shadow-xl',
      }
    },
    badge: {
      base: 'inline-flex items-center',
      rounded: 'rounded-md',
      font: 'font-medium',
      size: {
        xs: 'text-xs px-1.5 py-0.5',
        sm: 'text-xs px-2 py-1',
        md: 'text-sm px-2 py-1',
        lg: 'text-sm px-2.5 py-1.5',
      },
      color: {
        white: {
          solid: 'ring-1 ring-inset ring-gray-300 dark:ring-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-900',
        },
        gray: {
          solid: 'ring-1 ring-inset ring-gray-300 dark:ring-gray-700 text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-gray-800',
        },
        black: {
          solid: 'text-white dark:text-gray-900 bg-gray-900 dark:bg-white',
        },
      },
      variant: {
        solid: 'bg-{color}-500 dark:bg-{color}-400 text-white dark:text-gray-900',
        outline: 'text-{color}-500 dark:text-{color}-400 ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400',
        soft: 'bg-{color}-50 dark:bg-{color}-400 dark:bg-opacity-10 text-{color}-500 dark:text-{color}-400',
        subtle: 'bg-{color}-50 dark:bg-{color}-400 dark:bg-opacity-10 text-{color}-500 dark:text-{color}-400 ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400 ring-opacity-25 dark:ring-opacity-25',
      },
      default: {
        size: 'sm',
        variant: 'solid',
        color: 'primary',
      },
    }
  }
})