<script lang="ts" setup>
const year = (new Date()).getFullYear()

type City = {
  name: string
  slug: string
}

type District = {
  name: string
  slug: string
}

export interface Props {
  closeCities?: City[]
  district?: District
}

const props = withDefaults(defineProps<Props>(), {
  closeCities: () => ([]),
  district: undefined
})
</script>

<template lang="pug">
footer.layout-footer.pt-20.pb-8.bg-slate-700.text-slate-50
  .container.mx-auto.px-3
    template(v-if="closeCities.length")
      .flex.flex-col.justify-between.gap-6(class="md:flex-row")
        p.text-lg.font-semibold.shrink-0 Städte in der Nähe
        .flex.flex-col.gap-6.flex-wrap(class="md:flex-row justify-end")
          nuxt-link(
            v-if="props.district"
            :to="`/landkreis/${props.district.slug}`"
            :title="`iKFZ Außerbetriebsetzung ${props.district.name}`"
          ).font-thin {{ props.district.name }}
          nuxt-link(
            v-for="city in closeCities"
            :key="city.slug"
            :to="`/stadt/${city.slug}`"
            :title="`iKFZ Außerbetriebsetzung in ${city.name}`"
          ).font-thin {{ city.name }}
      hr.my-6.border-slate-900
    
    .flex.gap-12.justify-between.flex-col(class="md:flex-row")
      div
        img(src="/images/logo-white.svg" alt="Abmeldung Digital" style="height: 30px; width: auto;")
      .links.flex.gap-6.flex-col(class="md:flex-row lg:gap-8")
        nuxt-link(to="/staedte") Städte
        nuxt-link(to="/landkreise") Landkreise

    hr.my-6.border-slate-900
    .flex.justify-between.flex-col-reverse.gap-10(class="md:flex-row")
      span.text-sm.font-thin © {{ year }} blackbird GmbH
      .links.flex.gap-6.flex-col(class="md:flex-row lg:gap-8")
        nuxt-link(to="/impressum") Impressum
        nuxt-link(to="/datenschutz") Datenschutz
        nuxt-link(to="/agb") AGB
        nuxt-link(to="/widerrufsrecht") Widerrufsrecht
</template>

<style lang="sass">
.layout-footer
  z-index: 1001
  position: relative
</style>
