export default defineNuxtPlugin(() => {
  useNuxtApp().$trpc.public.getWartungsmodus.query().then(wartungsarbeiten => {
    if (wartungsarbeiten) {
      useToast().add({
        title: 'Wartungsarbeiten',
        description: 'Das Kraftfahrt-Bundesamt führt Wartungsarbeiten durch. Sie können Anträge einreichen, diese werden aber erst nach Abschluss der Wartungsarbeiten (Donnerstag Morgen) bearbeitet.',
        color: 'red',
        timeout: 0
      })
    }
  })
})
