<script lang="ts" setup>
import { useEventListener } from '@vueuse/core'

const checkListItemsVorbereitung = [
  'Zulassungsbescheinigung Teil 1 bereitlegen',
  'Kfz-Kennzeichen vom Fahrzeug abnehmen'
]

const checkListItemsGenerelleDaten = [
  'E-Mail (zum Versenden der Abmeldebescheinigung)',
  'Kennzeichen-Kombination',
  'FIN aus der Zulassungsbescheinigung Teil 1'
]

const checkListItemsSicherheitscodeZb1 = [
  'Feld mit Sicherheitscode finden',
  'Vorsichtig den versteckten Sicherheitscode freilegen',
  '7-stelligen Code in die Eingabemaske eintragen'
]

const checkListItemsSicherheitscodeKennzeichen = [
  'U-Schnitt um das Bundesland-Wappen vornehmen',
  'Folie abziehen & Code freilegen',
  '3-stelligen Code in die Eingabemaske eingeben'
]

const activeSlide = ref(0)

function goToSlide (index: number) {
  if (index < 0 || index > 4) return
  activeSlide.value = index
  trackEvent('explanation_slider', `go_to_slide_${index + 1}`)
}

useEventListener(window, 'blur', () => {
  if (document?.activeElement?.tagName === 'IFRAME') {
    switch (document.activeElement?.title) {
      case 'Sicherheitscodes auf der Zulassungsbescheinigung Teil 1 freilegen | einfach erklärt':
        trackEvent(document.activeElement?.attributes.section.nodeValue, 'youtube_zb1')
        break;

      case 'Sicherheitscodes auf Kfz-Kennzeichen freilegen | einfach erklärt':
        trackEvent(document.activeElement?.attributes.section.nodeValue, 'youtube_kennzeichen')
        break;
    
      default:
        break;
    }
  }
})
</script>

<template lang="pug">
.explanation-slider.max-w-xl.mx-auto(class="lg:max-w-full")
  .flex.gap-12.items-center(class="lg:flex-col")
    .flex.gap-4.justify-start(class="lg:justify-center")
      UButton(
        :disabled="activeSlide === 0"
        @click='goToSlide(activeSlide - 1)' 
        icon="i-heroicons-chevron-left"
        :ui="{ padding: { sm: 'px-2.5 py-2.5' } }"
        v-ga-event="{ name: 'explanation_slider', action: 'arrow_navigation' }"
      )

      UButton(
        :disabled="activeSlide === 4"
        @click='goToSlide(activeSlide + 1)' 
        icon="i-heroicons-chevron-right"
        :ui="{ padding: { sm: 'px-2.5 py-2.5' } }"
        v-ga-event="{ name: 'explanation_slider', action: 'arrow_navigation' }"
      )

    .step-indicator
      .step(
        v-ga-event="{ name: 'explanation_slider', action: 'indicator_navigation' }"
        :class="{ active: activeSlide === 0, completed: activeSlide > 0 }"
        @click="goToSlide(0)"
      )
        .icon.p-2.border-2.border-gray-100.rounded-lg.flex.justify-center.items-center.inline-block.bg-white
          UIcon(name="i-heroicons-queue-list").w-6.h-6

        p.mt-2
          strong Vorbereitung

        p.mt-1.text-sm.font-light Dokumente & Kennzeichen

      .step(
        v-ga-event="{ name: 'explanation_slider', action: 'indicator_navigation' }"
        :class="{ active: activeSlide === 1, completed: activeSlide > 1 }"
        @click="goToSlide(1)"
      )
        .icon.p-2.border-2.border-gray-100.rounded-lg.flex.justify-center.items-center.inline-block.bg-white
          UIcon(name="i-heroicons-pencil-square").w-6.h-6

        p.mt-2
          strong Generelle Daten

        p.mt-1.text-sm.font-light E-Mail, Kennzeichen & FIN

      .step(
        v-ga-event="{ name: 'explanation_slider', action: 'indicator_navigation' }"
        :class="{ active: activeSlide === 2, completed: activeSlide > 2 }"
        @click="goToSlide(2)"
      )
        .icon.p-2.border-2.border-gray-100.rounded-lg.flex.justify-center.items-center.inline-block.bg-white
          UIcon(name="i-heroicons-qr-code").w-6.h-6

        p.mt-2
          strong Sicherheitscode

        p.mt-1.text-sm.font-light Zulassungsbescheinigung Teil 1

      .step(
        v-ga-event="{ name: 'explanation_slider', action: 'indicator_navigation' }"
        :class="{ active: activeSlide === 3, completed: activeSlide > 3 }"
        @click="goToSlide(3)"
      )
        .icon.p-2.border-2.border-gray-100.rounded-lg.flex.justify-center.items-center.inline-block.bg-white
          UIcon(name="i-heroicons-qr-code").w-6.h-6

        p.mt-2
          strong Sicherheitscode

        p.mt-1.text-sm.font-light Kfz-Kennzeichen

      .step(
        v-ga-event="{ name: 'explanation_slider', action: 'indicator_navigation' }"
        :class="{ active: activeSlide === 4, completed: activeSlide > 4 }"
        @click="goToSlide(4)"
      )
        .icon.p-2.border-2.border-gray-100.rounded-lg.flex.justify-center.items-center.inline-block.bg-white
          UIcon(name="i-heroicons-check").w-6.h-6

        p.mt-2
          strong Abgemeldet

        p.mt-1.text-sm.font-light Bescheinigung

  .slide.vorbereitung(:class="{ active: activeSlide === 0 }")
    .content-col
      p.step-heading Schritt 1
      h3.text-3xl.font-semibold.text-gray-900 Vorbereitung
      p Bevor Sie loslegen können, sollten Sie noch Ihre Zulassungsbescheinigung Teil 1 und die Kfz-Kennzeichen bereitlegen, da Sie diese für die Online-Abmeldung brauchen.
      CheckList(:items="checkListItemsVorbereitung").mt-4.font-light.text-left.text-gray-600.mt-14
      
      p.mt-6.hidden(class="lg:block")
        nuxt-link(to="/ausserbetriebsetzung/vorbereitung")
          UButton(
            size="sm"
            v-ga-event="{ name: 'cta_button', action: 'section_explanation_slider' }"
          ) Jetzt online abmelden

    .image-col
      NuxtImg(
        src="/images/kennzeichen-zb.png"
        alt="Bild von Kfz-Kennzeichen und Zulassungsbescheinigung Teil 1"
        loading="lazy"
        width="368"
        style="aspect-ratio: 3/2;"
      ).drop-shadow-xl

  .slide.generelle-daten(:class="{ active: activeSlide === 1 }")
    .content-col
      p.step-heading Schritt 2
      h3 Generelle Daten
      p Wir brauchen für die Kfz-Abmeldung sowohl einige Daten über das Fahrzeug, als auch Ihre Kontaktdaten.
      CheckList(:items="checkListItemsGenerelleDaten").mt-4.font-light.text-left.text-gray-600.mt-14
      
      p.mt-6.hidden(class="lg:block")
        nuxt-link(to="/ausserbetriebsetzung/vorbereitung")
          UButton(
            size="sm"
            v-ga-event="{ name: 'cta_button', action: 'section_explanation_slider' }"
          ) Jetzt online abmelden

    .image-col
      NuxtImg(
        src="/images/fin-auf-zb1-markiert.png"
        alt="Bild von Kfz-Kennzeichen und Zulassungsbescheinigung Teil 1"
        loading="lazy"
        width="368"
        style="aspect-ratio: 3/2;"
      ).drop-shadow-xl

  .slide.sicherheitscode-zb-1(:class="{ active: activeSlide === 2 }")
    .content-col
      p.step-heading Schritt 3
      h3 Sicherheitscode ZB 1
      p Ihre Zulassungsbescheinigung Teil 1 enthält einen versteckten sicherheitscode, der für die Kfz-Abmeldung gebraucht wird.
      CheckList(:items="checkListItemsSicherheitscodeZb1").mt-4.font-light.text-left.text-gray-600.mt-14
      
      p.mt-6.hidden(class="lg:block")
        nuxt-link(to="/ausserbetriebsetzung/vorbereitung")
          UButton(
            size="sm"
            v-ga-event="{ name: 'cta_button', action: 'section_explanation_slider' }"
          ) Jetzt online abmelden

    .image-col
      .relative
        iframe(
          width='320'
          height='320' 
          src='https://www.youtube.com/embed/NfsMb2Zdxxg?mute=1&loop=1&color=white&modestbranding=1&playsinline=1&rel=0&playlist=NfsMb2Zdxxg'
          title='Sicherheitscodes auf der Zulassungsbescheinigung Teil 1 freilegen | einfach erklärt'
          frameborder='0'
          allow='accelerometer; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          class="aspect-[1/1]"
          allowfullscreen
          loading="lazy"
          section="explanation_slider"
        ).w-80.h-80.drop-shadow-xl

  .slide.sicherheitscode-kennzeichen(:class="{ active: activeSlide === 3 }")
    .content-col
      p.step-heading Schritt 4
      h3 Sicherheitscode Kennzeichen
      p In den Plaketten Ihrer Kennzeichen sind unter dem Wappen des Bundeslandes weitere Sicherheitscodes versteckt, die für die Online-Abmeldung gebraucht werden.
      CheckList(:items="checkListItemsSicherheitscodeKennzeichen").mt-4.font-light.text-left.text-gray-600.mt-14
      
      p.mt-6.hidden(class="lg:block")
        nuxt-link(to="/ausserbetriebsetzung/vorbereitung")
          UButton(
            size="sm"
            v-ga-event="{ name: 'cta_button', action: 'section_explanation_slider' }"
          ) Jetzt online abmelden

    .image-col
      iframe(
        width='320'
        height='320' 
        src='https://www.youtube.com/embed/MgTPaqiALoY?mute=1&loop=1&color=white&modestbranding=1&playsinline=1&rel=0&playlist=MgTPaqiALoY'
        title='Sicherheitscodes auf Kfz-Kennzeichen freilegen | einfach erklärt'
        frameborder='0'
        allow='accelerometer; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        class="aspect-[1/1]"
        allowfullscreen
        loading="lazy"
        section="explanation_slider"
      ).w-80.h-80.drop-shadow-xl

  .slide.sicherheitscode-kennzeichen(:class="{ active: activeSlide === 4 }")
    .content-col
      p.step-heading Schritt 5
      h3 Fahrzeug ist offiziell abgemeldet
      p Nachdem Sie die Gebühren per Online-Zahlung beglichen haben, ist Ihr Fahrzeug abgemeldet und Sie erhalten die Bestätigung als PDF.
      CheckList(:items="checkListItemsSicherheitscodeKennzeichen").mt-4.font-light.text-left.text-gray-600.mt-14
      
      p.mt-6.hidden(class="lg:block")
        nuxt-link(to="/ausserbetriebsetzung/vorbereitung")
          UButton(
            size="sm"
            v-ga-event="{ name: 'cta_button', action: 'section_explanation_slider' }"
          ) Jetzt online abmelden

    .image-col
      NuxtImg(
        src="/images/abmeldebescheinigung-screenshot.png"
        alt="Bild von Kfz-Kennzeichen und Zulassungsbescheinigung Teil 1"
        loading="lazy"
        width="368"
        style="aspect-ratio: 5/4;"
      ).drop-shadow-xl.border.border-gray-100

</template>

<style lang="sass">
.explanation-slider
  .step-indicator
    @apply w-40 grid grid-cols-5 gap-6 lg:w-full lg:mb-12

    .step
      @apply relative flex flex-col items-center cursor-pointer text-center w-4 h-4 bg-slate-200 rounded-full lg:w-full lg:h-full lg:bg-white

      &:after
        content: ''
        height: 1px
        transform: translate(16px, 7px)
        width: 21px
        z-index: 1
        @apply bg-slate-200 top-0 left-0 absolute

        @screen lg
          @apply w-full
          transform: translate(calc(50% + 10px), 20px)

      &:last-child:after
        @apply hidden

      .icon
        z-index: 2
        @apply bg-white

      & *
        @apply hidden lg:block

      p strong
        @apply font-semibold text-gray-600

      &.active
        @apply bg-slate-600 lg:bg-white

        .icon
          @apply bg-slate-600 text-white

        p strong
          @apply font-bold text-gray-800

      &.completed
        @apply bg-slate-400 lg:bg-white

        &:after
          @apply bg-slate-400
  .slide
    @apply grid-cols-1 gap-12 lg:grid-cols-2 hidden mt-6 lg:mt-12

    &.active
      @apply grid

    .image-col
      @apply col-span-1 p-2 lg:bg-slate-50 flex items-center justify-start lg:justify-center lg:py-12

    .content-col
      @apply col-span-1 flex flex-col justify-center

      h3
        @apply text-xl font-semibold text-gray-900 lg:text-3xl

      p
        @apply text-sm font-light mt-3 text-gray-600 lg:text-lg

        &.step-heading
          @apply text-base font-semibold text-gray-400

      li
        @apply text-sm lg:text-lg


</style>
