<script lang="ts" setup>
defineProps<{
  cityName: string
  stine?: boolean
}>()

const checkListItems = [
  'Offizielle Abmeldebescheinigung als PDF in ca. 3 Minuten',
  '24/7 verfügbar für alle Fahrzeugtypen (Auto, Motorrad etc.)',
  '100% Geld-zurück-Garantie'
]
</script>

<template lang="pug">
.hero.py-12(class="lg:py-24")
  .grid.grid-cols-1.items-center.gap-12(class="md:grid-cols-2")
    .content
      h1.text-3xl.font-semibold.text-slate-50(class="md:text-4xl lg:text-5xl")
        | {{ cityName ? `${cityName}: ` : '' }}Auto online abmelden

      .flex.flex-col-reverse(class="md:flex-col lg:gap-12").mt-8.gap-4
        CheckList(:items="checkListItems").mt-4.font-light.text-left.text-white.mt-14
        div
          p(v-if="stine || !cityName")
            nuxt-link(to="/ausserbetriebsetzung/vorbereitung")
              button.primary.white(
                v-ga-event="{ name: 'cta_button', action: 'section_hero' }"
              ) Jetzt Fahrzeug abmelden

          template(v-else)
            .block
              button.primary.white.mt-12(disabled style="opacity: 0.3; cursor: no-drop;") Jetzt Fahrzeug abmelden
            p.text-red-300.mt-4.max-w-md.text-sm Die Onlineplattform zur Kfz-Abmeldung der Zulassungsstelle in {{ cityName }} ist aktuell nicht verfügbar.

    .image.flex.items-center.justify-start(
      class="md:justify-center"
    )
      NuxtImg(
        src="/images/screen-preview-hero.png"
        alt="Hero"
        style="aspect-ratio: 314 / 256;"
        class="sm:max-w-sm sm:w-auto sm:h-68 md:max-w-full lg:h-96"
        sizes="300px md:400px lg:600px"
        preload
      ).w-full.max-w-xs

</template>

<style lang="sass">
.hero
  .image
    @apply order-1
    
    @screen md
      @apply -order-none

  .content
    @apply order-2

    @screen md
      @apply -order-none
</style>
