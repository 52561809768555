import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  if (useRuntimeConfig().public.target !== 'production') return

  Sentry.init({
    Vue: nuxtApp.vueApp,
    dsn: 'https://3a4492eb945763a162a1f37d093914f7@o85766.ingest.sentry.io/4505940170506240',
    tracesSampleRate: 1.0,
    ignoreErrors:[
      'Form validation failed', // Ignore form validation errors from nuxt-ui
    ]
  })
})
